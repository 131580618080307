@media only screen and (max-width: 750px) {

	// Body
	//html{overflow-x: hidden;}
	body {
		width: auto;

		opacity: 1;
		// 	font-size: 13px;
		//	background: #fcfcf7;

	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar
	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		h2 {}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}

	#FS {
		width: 90%;
		margin: 0 5% 10px 5%;
		position: relative;
		top: auto;
		right: auto;

		#search-field {
			width: 88%;
			padding: 0 5% 0 7%;
		}
	}


	// Mainholder
	body.body_1501 #mainholder,
	#mainholder {
		width: 100%;
		padding: 0;
	}

	// Logo bar
	#logobar {
		margin: 0;
		padding: 0;
		width: 100%;
		text-align: center;
		min-height: 0;

		img#logo {
			height: 85px;
			width: auto;
			margin: 0;
		}

		@media only screen and (max-width: 370px) {
			max-width: 80%;
			height: auto;
		}
	}

	//Navholder
	div#navholder {
		width: 100%;
		margin: 0;
		display: block;

		nav {
			width: 100%
		}

		ul.nav {
			width: 100%;
		}
	}

	// Contents

	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 84%;
		padding: 5%;
		margin: 3%;
	}

	.content {
		width: 94%;
		padding: 0;
		margin: 3%;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 94%;
		padding: 0;
		margin: 3%;
	}

	// Slider
	//#slider{width: 100%;height: 200px;overflow: hidden;border: none;}

	// Header
	header {
		background: rgba(0, 0, 0, .75);
		// padding: 60px 0 0 0;

		.wrapper {
			padding: 0;
		}
	}

	// Footer
	footer {
		.wrapper {
			width: 90%;
			padding: 5%;
		}
	}

	//Slider
	#slider {
		width: 94%;
		padding: 0;
		margin: 0 3%;
		height: auto;
		display: inline-block;
	}

	.nivoSlider {
		position: relative;
	}

	/* **************************************************** */

	//Logo
	#logobar img#logo {
		margin: 30px 0;
		height: auto;
		width: 80%;

		@media only screen and (max-width: 370px) {
			margin: 30px 0;
			width: 100%;
			height: auto;
		}

		#logobar {
			margin: 0;
			padding: 0;
			width: 100%;
			text-align: center;
			min-height: 0;

		}
	}

	span.strapline {
		letter-spacing: -0.05em;
	}

	//home box
	.home-box {
		width: 94%;
		padding: 0;
		margin: 50px 3% 20px 3%;
		display: inline-block;

		.SideHeading {
			width: 100%;
		}

		.quick-links {
			width: 100%;
			margin: 20px 0;
		}
	}

	//more box
	.box-more {
		a.more {
			width: auto;
			padding: 3px 5%;

			&:first-of-type {
				margin: 0 2% 0 0;
			}
		}
	}

	//Footer
	footer .wrapper p {
		margin: 15px 0;
	}

	footer .wrapper p.img-footer {
		margin: 0;
		display: block;
		margin-bottom: 15px;
	}

	footer .wrapper p:first-of-type {
		margin: 0;
	}

	footer #credit {
		float: none;
		text-align: left;
	}


	//Content
	.Content1Column {
		h1 {
			margin: -6% -6% 20px -6%;
			padding: 5%;
			font-size: 1.7em;
		}

		h2 {
			font-size: 1.3em;
		}
	}

	.Content2Column {
		h1 {
			margin: -6% -6% 20px -6%;
			padding: 5%;
			font-size: 1.7em;
		}

		h2 {
			font-size: 1.3em;
		}
	}

	.Content3Column {
		h1 {
			margin: -6% -6% 20px -6%;
			padding: 5%;
			font-size: 1.7em;
		}

		h2 {
			font-size: 1.3em;
		}
	}

}
// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body {
	color: #444;
	font-size: 16px;
	font-family: $body-font;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
	//background: url(/i/design/design-inside.jpg) no-repeat center top;opacity: .5;
}

// LINKS
body a {
	color: #10863C;
	text-decoration: none;
	transition: (all 0.3s ease-in-out);
}

body a:hover,
body a:focus {
	text-decoration: underline;
	transition: (all 0.3s ease-in-out);
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 28px;
	margin: -5px 0 20px 0;
	color: #77A8D2;
	line-height: 1.2;
}

h2 {
	font-size: 20px;
	margin: 25px 0 20px 0;
	color: #333;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 5px 0;
	padding: 5px 10px;
	background-color: #E6C119;
	color: #fff;
	border-radius: 10px 10px 0 0;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background-image: url(/i/icons/bullet_black.png);
	background-repeat: no-repeat;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #fff;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	background: url(/i/design/bg-header.png) repeat center top;
}

header>.wrapper {
	padding: 0 0 0 0;
	position: relative;
	z-index: 60;
}

#logobar {
	margin: 0;
	padding: 0;
	z-index: 8;
	display: inline-block;
	text-align: center;
}

#logobar img#logo {
	display: inline-block;
	vertical-align: top;
	margin: 5px 0 0 0;
}

#logobar h1 {
	width: 200px;
	height: 120px;
	;
	margin: 0;
	padding: 0;
	font-family: $logo-font;
	font-weight: bold;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
}

#logobar h1 a {
	color: #fff;
}

#logobar h1 a:hover {
	text-decoration: none;
}

span.line1 {
	position: absolute;
	left: 27px;
	top: 13px;
	font-size: 29px;
	letter-spacing: 0.09em;
}

span.line2 {
	position: absolute;
	left: 29px;
	top: 47px;
	font-size: 18px;
	text-transform: none;
	letter-spacing: 0.15em;
	width: 82%;

	&:before {
		content: "";
		width: 53px;
		height: 1px;
		background: #fff;
		position: absolute;
		top: 11px;
		left: 0;
	}

	&:after {
		content: "";
		width: 53px;
		height: 1px;
		background: #fff;
		position: absolute;
		top: 11px;
		right: 0;
	}
}

span.line3 {
	position: absolute;
	left: 27px;
	top: 62px;
	font-size: 34px;
	letter-spacing: 0.15em;
}

span.strapline {
	position: absolute;
	left: 0;
	top: 100px;
	font-family: $body-font;
	font-size: 10px;
	font-weight: lighter;
	white-space: nowrap;
	letter-spacing: -0.02em;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	margin: 0;
	padding: 0;
	position: absolute;
	top: em(7px);
	right: 0;
	vertical-align: top;
	opacity: 0.6;
}

#FS #search-field {
	width: em(167px);
	height: 25px;
	box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6);
	color: #fff;
	font-size: em(12px);
	background: none;
	border-radius: 50px;
	vertical-align: top;
	padding: 0 em(25px) 0 em(10px);
}

#FS #search-submit {
	vertical-align: top;
	border: 0;
	padding: 6px 8px;
	margin: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_1501 #mainholder {
	width: 990px;
	margin: 0px auto;
	padding: 30px 0 35px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

#mainholder {
	width: 990px;
	margin: 0px auto;
	padding: 50px 0 35px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 990px;
	margin: 0 0 15px 0;
	padding: 30px;
	float: left;
	min-height: 450px;
	text-align: left;
	background: rgba(255, 255, 255, .9);

	h1 {
		background: linear-gradient(to bottom, #10863C 0%, darken(#10863C, 15%) 100%);
		color: #fff;
		margin: -30px -30px 20px -30px;
		padding: 6px 0 6px 28px;
		letter-spacing: -0.02em;
	}

	h2 {
		color: #363637;
		font-size: 26px;
		line-height: 140%;
	}
}

//Content 2
.Content2Column {
	width: 605px;
	margin: 0;
	padding: 30px;
	float: right;
	min-height: 450px;
	text-align: left;
	background: rgba(255, 255, 255, .9);

	h1 {
		background: linear-gradient(to bottom, #10863C 0%, darken(#10863C, 15%) 100%);
		;
		color: #fff;
		margin: -30px -30px 20px -30px;
		padding: 6px 0 6px 28px;
		letter-spacing: -0.02em;
	}

	h2 {
		color: #363637;
		font-size: 26px;
		line-height: 140%;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 450px;
	text-align: left;
	background: rgba(255, 255, 255, .9);

	h1 {
		background: linear-gradient(to bottom, #10863C 0%, darken(#10863C, 15%) 100%);
		;
		color: #fff;
		margin: -30px -30px 20px -30px;
		padding: 6px 0 6px 28px;
		letter-spacing: -0.02em;
	}

	h2 {
		color: #363637;
		font-size: 26px;
		line-height: 140%;
	}

}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 295px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnRight {
	width: 295px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack


//home box
.home-box {
	display: block;
	margin: 498px 0 0 0;

	.SideHeading {
		width: 350px;
		display: inline-block;
		vertical-align: top;
		margin: 0;

		&:last-of-type {
			float: right;
		}

		h2 {
			padding: 8px 0 10px 0;
		}

		ul.SideList {
			min-height: 268px;
		}
	}

	//Diary dates
	#SideHeadingDiary {
		ul.SideList p.date {
			width: 16%;
		}

		ul.SideList p.title {
			width: 79%;
			font-weight: 600;
			letter-spacing: -1px;
		}
	}

	//Box more
	.box-more {
		text-align: center;
		padding: 15px 0 30px 0;

		a.more {
			width: 115px;
			background: #E6C119;
			color: #fff;
			text-align: center;
			text-transform: uppercase;
			display: inline-block;
			border-radius: 2px;
			padding: 3px 5px;
			font-size: 13px;

			&:first-of-type {
				margin: 0 58px 0 0;
			}

			&:hover {
				background: #10863C;
				text-decoration: none;
			}


		}

		a.more-right {
			background: #E6C119;

			&:hover {
				background: #10863C;
				text-decoration: none;
			}
		}
	}
}

//Quick links
.quick-links {
	display: inline-block;
	vertical-align: top;
	margin: 0 0 0 27px;
	width: 230px;

	h2 {
		background: #E6C119;
		color: #fff;
		margin: 0;
		text-transform: uppercase;
		font-size: 22px;
		margin: 0;
		padding: 6px 0 12px 0;
		text-align: center;
		font-weight: 300;

		img {
			display: inline-block;
			margin: -5px 0 0 2px;
		}
	}

	//List
	ul {
		background: rgba(255, 255, 255, 0.9);
		padding: 10px 0;
		min-height: 268px;

		li {
			margin: 0;
			padding: 0;
			background: none;

			a {
				color: #000;
				font-weight: 600;
				letter-spacing: -1px;
				background: url(/i/design/arrow-news-red.png) no-repeat 30px 11px;
				padding: 12px 52px 12px 62px;
				display: block;
				font-size: 14px;
				line-height: 120%;
				text-decoration: none;

				&:hover {
					background-color: #dac35c;
					color: #fff;
				}
			}
		}
	}
}

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0 0 28px 0;
	padding: 0;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	background: #10863C;
	color: #fff;
	margin: 0;
	text-transform: uppercase;
	font-size: 22px;
	text-align: center;
	padding: 8px 0 10px 0;
	font-weight: 300;

	img {
		display: inline-block;
		margin: -4px 0 0 4px;
	}

}

.SideHeading:first-of-type h2 {
	background: #10863C;

}

.SideHeading:last-of-type h2 {
	background: #10863C;

}

.SideHeading {

	.related-pages {

		li {
			a {
				font-weight: 600;
				letter-spacing: -1px;
				font-size: 14px;

			}
		}

		img {
			display: inline-block;
			margin: -4px 0 0 4px;
		}
	}
}



// List
ul.SideList {
	margin: 0;
	padding: 10px 0 0 0;
	border: 0;
	list-style: none;
	background: rgba(255, 255, 255, 0.9);
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
	border-bottom: 0;
}

ul.SideList li a {
	margin: 0;
	padding: 10px 30px;
	color: #000;
	font-weight: normal;
	display: block;
	font-size: 14px;
}

ul.SideList li a:hover {
	background: #5eb77f;
	color: #FFF;
	text-decoration: none;
}

ul.side-list-right li a:hover {
	background: #5eb77f;
	color: #FFF;
	text-decoration: none;
}

ul.SideList li a.child {
	padding-left: 40px !important;
	background: url(/i/design/lite/bg_nav_li_a_child_white.png) 20px 15px no-repeat;
}

ul.SideList li a.child:hover {
	background: #10863C url(/i/design/lite/bg_nav_li_a_child_white.png) 20px 15px no-repeat;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	font-family: $logo-font;
	text-transform: uppercase;
}

ul.SideList p.title {
	float: right;
	width: 75%;
}

// More links
.box-more {
	text-align: center;
	padding: 15px 0 30px 0;

	a.more {
		width: 115px;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		display: inline-block !important;
		border-radius: 2px;
		padding: 3px 5px;
		letter-spacing: -0.05em;

		&:first-of-type {
			margin: 0 0 0 0;
		}

		&:hover {
			background: #10863C;
			text-decoration: none;
		}
	}

	.news-mobile-btns {
		background: #10863C;

		&:hover {
			background: #6ec969 !important;
		}
	}

	.diary-mobile-btns {
		background: #10863C;

		&:hover {
			background: #6ec969 !important;
		}
	}
}

//News
#SideHeadingNews {
	ul.SideList p.title {
		width: 100%;
		font-weight: 600;
		letter-spacing: -1px;

		img {
			display: inline-block;
			margin: 0 16px 0 0;
		}
	}
}

//Diary
#SideHeadingDiary {
	ul.SideList p.date {
		font-weight: bold;
		font-size: 14px;
		width: 20%;
		color: #E6C119;
	}

	ul.SideList p.title {
		width: 75%;

		img {
			display: inline-block;
			margin: 0 16px 0 0;
		}
	}
}

//Related pages
#SideHeadingPages {
	h2 {
		text-indent: -14px;
		background: #10863C;

	}

	ul.SideList {
		padding: 14px 0 20px 0;
	}

	ul.SideList li a {
		padding: 10px 30px;
		font-size: 14px;

		&:hover {
			background: #5eb77f;
		}
	}
}

// -----------------------------------//
// FULL WIDTH LISTINGS
// -----------------------------------//

h2.ListingHead {
	@extend h3;
	margin: 0;
	clear: both;
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	color: #fff;
	font-size: 17px;
}

ul#DiaryListing,
ul#NewsListing {
	border: 1px solid #A5A5A6;
	margin: 0 0 10px 0;
	padding: 10px 20px;
	clear: left;
}

ul#NewsListing li {
	padding-left: 25px;
	background: url(/i/icons/comment.gif) no-repeat;
	list-style-type: none;
}

ul#DiaryListing li {
	background-image: none;
	padding-left: 0;
}

img.ListingIcon {
	margin: 0 10px 0 0;
	float: left;
}

// -----------------------------------//
// SLIDESHOW (GALLERIES)
// -----------------------------------//

.SScatHolder {
	width: 500px;
	height: 304px;
	color: #FFF;
	background-image: url(/admin/i/GalleryGroupPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	display: block;
}

.SSHolder {
	width: 500px;
	height: 375px;
	color: #FFF;
	background-image: url(/admin/i/GalleryPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
}

.SSWrapper {
	border: 1px solid #666;
	margin: 5px 0 10px 0;
	padding: 0;
	height: 375px;
	vertical-align: middle;
	width: 500px;
	background-color: #FFF;
}

// Tidy up

#SSlistblock {
	margin: 0;
	padding: 0;

	.singlegallery {
		margin: 0 0 16px;

		.thumblink {
			float: left;
			width: 30%;

			.thumb {
				max-width: 100%;
			}
		}

		.summary {
			float: right;
			width: 67%;
		}

		.title {
			margin: 4px 0;
		}

		.count {
			margin-top: 0;
		}

		.description {
			font-size: 12px;
		}
	}
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	background: #10863C;
}

footer>.wrapper {
	margin: 0 auto;
	padding: 20px 0px;
	color: #fff;
	font-size: em(12px);
	text-align: left;
}

footer>.wrapper p {
	margin: 5px 0;
	display: inline-block;
	vertical-align: top;
	line-height: 130%;
	letter-spacing: -0.015em;

	&:first-of-type {
		margin-right: 48px;
	}
}

footer>.wrapper p.img-footer {
	margin: -3px 0 0 118px;
}

footer>.wrapper a {
	color: #fff;
}

footer #credit {
	margin: 5px 5px 0 0;
	padding: 0;
	float: right;
	text-align: right;
}

footer .epping-forest {
	display: block;
	background: #fff;
	padding: 15px 0;
	margin: 0 auto;
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: #FFF;
$table-highlight-background-color: #666;
$table-highlight-font-weight: bold;
$table-border-color: #ccc;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

$blockquote-background-color: #363637;
$blockquote-border-color: #363637;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #999;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {}

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}
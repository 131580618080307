// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

div#navholder {

	position: relative;
	z-index: 400;
	@extend .clearfix;
	width: 100%;
	margin: -2px 0 0 0;

	nav {
		width: 100%;
	}

}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {

	display: table;
	width: 100%;
	margin: 0;
	list-style: none;

	// Top Level List

	li {
		margin: 0;
		padding: 0;
		background: none;
		position: relative;
		display: table-cell;
		text-align: center;
		white-space: nowrap;

		a {
			height: 45px;
			line-height: 60px;
			display: block;
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			position: relative;
			margin: 7px 10px;
			font-weight: 300;

			&:after {
				content: "";
				margin: 2px 0 0 0;
				width: 100%;
				height: 2px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: #fff;
				opacity: 0;
				transition: (all 0.3s ease-in-out);
			}
		}
	}

	>li:hover>a,
	>li>a:hover {
		color: #fff;
		text-decoration: none;

		&:after {
			bottom: 5px;
			opacity: 1;
			transition: (all 0.3s ease-in-out);
		}
	}

	// 2nd Level List

	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 0 20px 20px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
		}
	}

	// 2nd Level List Items

	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;

		a {
			background: #10863C;
			height: 28px;
			line-height: 28px;
			padding: 3px 12px;
			min-width: 150px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-transform: none;
			margin: 0;

			&:after {
				display: none;
			}
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: #479865;
			color: #fff;
			text-decoration: none;
		}
	}

	// Prepend 'more' arrow to parent list items

	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List 

	ul ul {
		z-index: 505;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
	}

}
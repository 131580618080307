// Global Variables

@import 'https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,400i,500,600,700,700i|PT+Serif:200,300,400,400i,500,600,700,700i';

//Font Variables
$body-font: 'Open Sans', sans-serif;
$logo-font: 'PT Serif', serif;

// Px to em function
$base-font-size: 14px;
@function em($target, $context: $base-font-size) {
	@return ($target / $context) * 1em;
}

// Partial Imports

@import 'c_reset';
@import 'c_site';
// @import 'c_site_sidebar';
@import 'c_default';
@import 'c_slider';
@import 'c_cmsstyles';

// Mobile / Responsive Imports

@import 'c_meanmenu';
@import 'c_respond';